
  import prepareLinkItem from '@/mixins/prepareLinkItem';
  export default {
    name: 'BaseLink',
    mixins: [prepareLinkItem],
    props: {
      link: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        baseLink: {},
      };
    },
    mounted() {
      this.baseLink = this.prepareLinkItem(this.link);
    },
  };
