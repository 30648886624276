import {fixedRouteSlugs} from '@/routes.config';
export default {
  methods: {
    prepareLinkItem(item) {
      const menuItem = {
        name: item.text,
      };

      let route = null;
      if (item.route) {
        route = item.route;
      } else if (item.page) {
        if (fixedRouteSlugs.includes(item.page.slug)) {
          route = {
            name: item.page.slug,
          };
        } else {
          route = {
            name: 'slug',
            params: {slug: item.page.slug},
          };
        }
      } else if (item.article) {
        route = {
          name: 'articles-category-article',
          params: {
            category: item.article.article_category.slug,
            article: item.article.slug,
          },
        };
      } else if (item.activity) {
        route = {
          name: 'landingpages-activities-activity',
          params: {
            activity: item.activity.slug,
          },
        };
      } else if (item.city) {
        route = {
          name: 'landingpages-cities-city',
          params: {
            city: item.city.slug,
          },
        };
      } else if (item.href) {
        menuItem.href = item.href;
      }

      menuItem.route = route;
      menuItem.target = item.target;
      menuItem.class = item.class;
      menuItem.icon = item.icon;
      menuItem.title = item.title;

      return menuItem;
    },
  },
};
