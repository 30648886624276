import EventBus from '@/events/EventBus';
import {createObjectFromQueryString} from '@/helper/QueryHelper';
import {
  prepareGeoApifyReverseSearchUrl,
  prepareGeoApifySearchByIdUrl,
  prepareGeoApifySearchUrl,
} from '@/helper/UrlHelper';
import axios from 'axios';
import {debounce} from 'lodash/function';
export default {
  data() {
    return {
      addPadding: false,
      showFilter: false,
      searchCarousel: 0,
      cycle: false,
      searches: [],
      descriptionLimit: 60,
      whereEntries: [],
      autocomplete_osm_model: null,
      whereSearch: null,
      isCleared: false,
      what: null,
      where: null,
      whereInput: null,
      distanceInput: 25000,
      activities: [],
      employmentTypes: [],
      industries: [],
      datePeriodStart: null,
      datePeriodEnd: null,
      dateStartFromNow: null,
      dateOpenEnded: null,
      salaryRange: [0, 200],
      salaryRangeMax: 200,
      salaryRangeMin: 0,
      currentSalarySlide: 0,
      currentSalaryNumber: null,
      salaryTypes: [
        //'nicht festgelegt',
        'pro Stunde',
        'pro Tag',
        'pro Monat',
        'pro Jahr',
        'auf Anfrage',
      ],
      salaryType: '',
      gender: '',
      userdataFilters: [],
      releasePeriodItems: [
        {
          value: 3600,
          text: this.$t('filter.last_hour'),
        },
        {
          value: 14400,
          text: this.$t('filter.last_4_hours'),
        },
        {
          value: 86400,
          text: this.$t('filter.last_24_hours'),
        },
        {
          value: 259200,
          text: this.$t('filter.last_3_days'),
        },
        {
          value: 604800,
          text: this.$t('filter.last_7_days'),
        },
      ],
      releasePeriod: {},
      additionalFilters: null,

      expandActivities: false,
      expandEmploymentTypes: false,
      expandIndustries: false,

      activityItems: [],
      industryItems: [],
      distanceItems: [
        {
          value: 25000,
          text: this.$t('distance.25_km'),
          counter: 5,
        },
        {
          value: 50000,
          text: this.$t('distance.50_km'),
          counter: 5,
        },
        {
          value: 100000,
          text: this.$t('distance.100_km'),
          counter: 5,
        },
        {
          value: 250000,
          text: this.$t('distance.250_km'),
          counter: 5,
        },
        {
          value: 500000,
          text: this.$t('distance.500_km'),
          counter: 5,
        },
      ],
      employmentTypeItems: [],
      genderItems: [
        {
          value: 'f',
          text: 'gender.female',
        },
        {
          value: 'm',
          text: 'gender.male',
        },
        {
          value: 'd',
          text: 'gender.diverse',
        },
      ],
      userdataFilterItems: [
        {
          value: 'age',
          text: 'filter.userdata.age',
          displayCheck: 'birthDate',
        },
        {
          value: 'bodyHeight',
          text: 'filter.userdata.bodyheight',
          displayCheck: 'bodyHeight',
        },
        /*{
          value: 'gender',
          text: 'filter.userdata.gender',
          displayCheck: 'gender',
        },*/
        {
          value: 'preferredActivities',
          text: 'filter.userdata.preferredActivities',
          displayCheckArray: 'preferredActivities',
        },
        {
          value: 'preferredIndustries',
          text: 'filter.userdata.preferredIndustries',
          displayCheckArray: 'preferredIndustries',
        },
      ],
    };
  },
  computed: {
    addressSearchRules() {
      return this.whereSearch && !this.autocomplete_osm_model
        ? [this.$t('filter.missing_select_value')]
        : [true];
    },
    displayActivityItems() {
      return this.expandActivities
        ? this.activityItems
        : this.activityItems.slice(0, 5);
    },
    displayIndustryItems() {
      return this.expandIndustries
        ? this.industryItems
        : this.industryItems.slice(0, 5);
    },
    displayEmploymentTypeItems() {
      return this.expandEmploymentTypes
        ? this.employmentTypeItems
        : this.employmentTypeItems.slice(0, 5);
    },
    formattedPeriodEnd() {
      return this.datePeriodEnd
        ? this.$dayjs(this.datePeriodEnd).format('DD.MM.YYYY')
        : null;
    },
    formattedPeriodStart() {
      return this.datePeriodStart
        ? this.$dayjs(this.datePeriodStart).format('DD.MM.YYYY')
        : null;
    },
  },
  watch: {
    whereSearch(val) {
      this.updateWhereSearch(val);
    },
    filterParams: function () {
      this.setFilterValues();
      this.searchCarousel = 0;
    },

    newSearchArray: function (val) {
      this.searches = val;
    },
    '$auth.user.starredCompanyIds': function () {
      this.updateDataFields();
    },

    facets: {
      deep: true,
      handler() {
        if (this.useFacets) this.setFacetsFromProp();
      },
    },
  },
  fetch() {
    if (this.useFacets) this.setFacetsFromProp();
    else {
      return this.$store
        .dispatch('api/jobPosting/getJobPostingsActiveKeys')
        .then((activeKeys) => {
          this.industryItems = this.prepareModelItemsWithCounter(
            activeKeys.industry_keys
          );
          this.employmentTypeItems = this.prepareModelItemsWithCounter(
            activeKeys.employment_type_keys
          );
          this.activityItems = this.prepareModelItemsWithCounter(
            activeKeys.activity_keys
          );
        });
    }
  },
  created() {
    if (process.client) {
      this.setUpSearches();
    }
    this.isCleared = false;
    this.activityItems.sort(function (a, b) {
      return b.counter - a.counter;
    });

    this.industryItems.sort(function (a, b) {
      return b.counter - a.counter;
    });

    this.updateDataFields();
    this.setFilterValues();
  },
  methods: {
    closeMenu(menuRef, date) {
      this.applyFilter(true);
      this.$refs[menuRef].save(date);
    },
    updateWhereSearch(val) {
      const filterValues = createObjectFromQueryString(this.filterParams);
      if (process.client) {
        if (!val) {
          return;
        }
        if (filterValues.osm_id && !this.isCleared) {
          this.fetchWhereEntryById(filterValues.distance, filterValues.osm_id);
        } else {
          this.fetchWhereEntriesByTitle(val);
        }
      }
    },
    setUpStartDate() {
      this.datePeriodStart = this.dateStartFromNow
        ? null
        : this.datePeriodStart;
      this.applyFilter(true);
    },
    setUpEndDate() {
      this.datePeriodEnd = this.dateOpenEnded ? null : this.datePeriodEnd;
      this.applyFilter(true);
    },
    setFacetsFromProp() {
      this.activityItems = [];
      this.industryItems = [];
      this.employmentTypeItems = [];

      this.$nextTick(() => {
        if (
          !this.facets ||
          !this.facets['activity'] ||
          !this.facets['industry'] ||
          !this.facets['employment_types']
        )
          return;

        const activityChoices = [];
        this.facets['activity'].forEach((item) => {
          this.activityItems.push({
            value: item.value,
            text: this.$t(item.value),
            counter: item.count,
          });
          activityChoices.push(item.value);
        });
        // Add selected items with 0 counter
        this.activities.forEach((activity) => {
          if (!activityChoices.includes(activity))
            this.activityItems.push({
              value: activity,
              text: this.$t(activity),
              counter: 0,
            });
        });

        const industryChoices = [];
        this.facets['industry'].forEach((item) => {
          this.industryItems.push({
            value: item.value,
            text: this.$t(item.value),
            counter: item.count,
          });
          industryChoices.push(item.value);
        });
        // Add selected items with 0 counter
        this.industries.forEach((industry) => {
          if (!industryChoices.includes(industry))
            this.industryItems.push({
              value: industry,
              text: this.$t(industry),
              counter: 0,
            });
        });

        const employmentTypeChoices = [];
        this.facets['employment_types'].forEach((item) => {
          this.employmentTypeItems.push({
            value: item.value,
            text: this.$t(item.value),
            counter: item.count,
          });
          employmentTypeChoices.push(item.value);
        });
        // Add selected items with 0 counter
        this.employmentTypes.forEach((employmentType) => {
          if (!employmentTypeChoices.includes(employmentType))
            this.industryItems.push({
              value: employmentType,
              text: this.$t(employmentType),
              counter: 0,
            });
        });
      });
    },
    async setUpSearches() {
      const allSavedSearches = await this.$localforage.getItem('SavedSearches');
      if (allSavedSearches && allSavedSearches.length > 0) {
        allSavedSearches.map((item) => {
          if (item.page === this.$route.path) {
            this.searches.push(item);
          }
        });
      }
    },
    repeatSearch(url) {
      this.searchCarousel = 0;
      this.whereSearch = null;
      this.autocomplete_osm_model = null;
      this.$router.push(url);
    },
    updateDataFields() {
      if (this.$auth.loggedIn) {
        const userdata = this.$auth.user.candidateProfile;
        this.userdataFilterItems = this.userdataFilterItems.filter(function (
          item
        ) {
          if (item.displayCheck && !userdata[item.displayCheck]) {
            return false;
          }
          return !(
            item.displayCheckArray &&
            (!userdata[item.displayCheckArray] ||
              !userdata[item.displayCheckArray][0])
          );
        });
        if (Object.values(this.$auth.user.starredCompanyIds).length - 1 > 0) {
          this.userdataFilterItems.push({
            value: 'favoritesOnly',
            text: 'filter.userdata.favoritesOnly',
            displayCheckArray: 'favoritesOnly',
          });
        }
      }
    },
    setSalary(salary) {
      this.currentSalarySlide = salary;
      this.currentSalaryNumber = salary;
      this.applyFilterWithThrottle();
    },
    getGeoLocation() {
      if (this.autocomplete_osm_model !== null) {
        return;
      }
      new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject();
        }
        // https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
        // ohne Timeout wartet er EWIG
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          timeout: 3000,
          enableHighAccuracy: true,
        });
      })
        .then((value) => {
          axios
            .get(
              prepareGeoApifyReverseSearchUrl(
                value.coords.latitude,
                value.coords.longitude
              )
            )
            .then((result) => {
              this.autocomplete_osm_model = result.data.features[0];
              this.whereEntries = result.data.features;
              this.applyFilter(true);
            })
            .catch((e) => {
              if (e.response.status !== 200) {
                this.$notifications.showNotification({
                  message: this.$t('geolocation.error_message'),
                  color: 'error',
                  timeout: 5000,
                });
              }
            });
        })
        .catch((e) => {
          this.$notifications.showNotification({
            message:
              e.code === 1
                ? this.$t('geolocation.is_denied')
                : this.$t('geolocation.unavailable'),
            color: 'error',
            timeout: 5000,
          });
        });
    },
    clearInput() {
      this.whereSearch = null;
      this.whereInput = null;
      this.autocomplete_osm_model = null;
      this.whereEntries = [];
      this.isCleared = true;
    },
    async fetchWhereEntryById(distance, id) {
      if (this.autocomplete_osm_model !== null) {
        return;
      }
      let result = await axios.get(prepareGeoApifySearchByIdUrl(id));
      if (
        result.data.features.length === 0 ||
        !result.data.features[0].formatted
      ) {
        result = await axios.get(
          prepareGeoApifyReverseSearchUrl(
            Number(distance.split(',')[0]),
            Number(distance.split(',')[1])
          )
        );
      }
      if (result.status != 200) {
        this.$notifications.showNotification({
          message: this.$t('geolocation.error_message'),
          color: 'error',
          timeout: 5000,
        });
      }
      this.whereEntries = result.data.features;
      this.autocomplete_osm_model = result.data.features[0];
    },
    fetchWhereEntriesByTitle(val) {
      if (val === this.whereInput && this.autocomplete_osm_model !== null) {
        return;
      }
      if (process.client) {
        // cancel pending call
        clearTimeout(this._timerId);

        // delay new call
        this._timerId = setTimeout(() => {
          axios
            .get(prepareGeoApifySearchUrl(val))
            .then((searchResults) => {
              this.whereEntries = searchResults.data.features;
              if (this.whereInput) {
                this.autocomplete_osm_model = this.whereEntries[0];
              }
              if (this.autocomplete_osm_model) {
                this.where = {
                  latitude: this.autocomplete_osm_model.properties.lat,
                  longitude: this.autocomplete_osm_model.properties.lon,
                };
                this.whereInput =
                  this.autocomplete_osm_model.properties.formatted;
              }
            })
            .catch((e) => {
              if (e.response.status !== 200) {
                this.$notifications.showNotification({
                  message: this.$t('geolocation.error_message'),
                  color: 'error',
                  timeout: 5000,
                });
              }
            });
        }, 500);
      }
    },
    setFilterValues() {
      this.resetFilterValues();
      const filterValues = createObjectFromQueryString(this.filterParams);

      if (Object.keys(filterValues).length === 0) {
        this.clearInput();
      }

      if (filterValues.distance && filterValues.whereInput) {
        this.distanceInput = Number(filterValues.distance.split(',')[2]);
        this.whereInput = filterValues.whereInput;
        this.updateWhereSearch(filterValues.whereInput);
      }

      if (filterValues.search) {
        this.what = filterValues.search;
      }

      if (filterValues['activity[]']) {
        this.activities = this.industries.concat(filterValues['activity[]']);
      }
      if (filterValues['industry[]']) {
        this.industries = this.industries.concat(filterValues['industry[]']);
      }
      if (filterValues['employmentTypesOr[]']) {
        this.employmentTypes = this.employmentTypes.concat(
          filterValues['employmentTypesOr[]']
        );
      }

      if (filterValues['datePeriodStart']) {
        this.datePeriodStart = filterValues['datePeriodStart'];
      }
      if (filterValues['datePeriodEnd']) {
        this.datePeriodEnd = filterValues['datePeriodEnd'];
      }
      this.dateStartFromNow = !!filterValues['dateStartFromNow'];
      this.dateOpenEnded = !!filterValues['dateOpenEnded'];
      if (filterValues['salary[min]']) {
        this.currentSalarySlide = filterValues['salary[min]'][0];
        this.currentSalaryNumber = filterValues['salary[min]'][0];
      }
      if (filterValues['salary[type]']) {
        this.salaryType = filterValues['salary[type]'][0];
      }
      if (filterValues['created']) {
        this.releasePeriod = this.releasePeriodItems.find(
          (data) => data.value == filterValues['created']
        );
      }
      if (filterValues['gender']) {
        this.gender = filterValues['gender'];
      }
      for (const key in filterValues) {
        if (key.startsWith('my_')) {
          this.userdataFilters.push(key.slice(3));
        }
      }
      if (filterValues['additionalFilters']) {
        this.additionalFilters = filterValues['additionalFilters'];
      }
    },
    applyFilterWithThrottle: debounce(function () {
      this.applyFilter(true);
    }, 2000),
    applyFilter(button = false) {
      this.addPadding = true;
      this.isCleared = false;
      const params = new URLSearchParams();
      if (this.what) {
        params.append('search', this.what);
      }
      if (this.autocomplete_osm_model && this.distanceInput) {
        params.append(
          'distance',
          `${this.autocomplete_osm_model.properties.lat},${this.autocomplete_osm_model.properties.lon},${this.distanceInput}`
        );
        this.whereInput = String(
          this.autocomplete_osm_model.properties.formatted
        );
        this.distanceInput = Number(this.distanceInput);
        params.append('whereInput', this.whereInput);
      }
      if (this.activities) {
        this.activities.forEach((activity) => {
          params.append('activity[]', activity);
        });
      }
      if (this.employmentTypes) {
        this.employmentTypes.forEach((employmentType) => {
          params.append('employmentTypesOr[]', employmentType);
        });
      }
      if (this.industries) {
        this.industries.forEach((industry) => {
          params.append('industry[]', industry);
        });
      }
      if (this.datePeriodStart) {
        params.append('datePeriodStart', this.datePeriodStart);
      }
      if (this.datePeriodEnd) {
        params.append('datePeriodEnd', this.datePeriodEnd);
      }
      if (this.dateStartFromNow) {
        params.append('dateStartFromNow', this.dateStartFromNow);
      }
      if (this.dateOpenEnded) {
        params.append('dateOpenEnded', this.dateOpenEnded);
      }
      if (this.gender) {
        params.append('gender', this.gender);
      }
      if (this.currentSalaryNumber && this.currentSalarySlide) {
        params.append('salary[min]', this.currentSalaryNumber);
      }
      if (this.salaryType) {
        params.append('salary[type]', this.salaryType);
      }
      if (this.releasePeriod && this.releasePeriod.value) {
        params.append('created', this.releasePeriod.value);
      }
      if (this.userdataFilters && this.$auth.loggedIn) {
        for (const i in this.userdataFilters) {
          params.append('my_' + this.userdataFilters[i], '1');
        }
      }
      if (this.additionalFilters) {
        params.append('additionalFilters', this.additionalFilters);
      }
      if (
        !this.what &&
        !this.autocomplete_osm_model &&
        !this.currentSalaryNumber &&
        !this.currentSalarySlide &&
        !this.salaryType &&
        this.employmentTypes.length == 0
      ) {
        this.addPadding = false;
      }
      const event = button ? 'listing-filter-button' : 'listing-filter';
      EventBus.$emit(event, params);
      this.$emit(event, params);
    },
    resetFilter() {
      this.addPadding = false;
      this.autocomplete_osm_model = null;
      this.resetFilterValues();
      this.emitResetFilterEvent();
    },
    resetFilterValues() {
      this.what = null;
      this.where = null;
      this.whereInput = null;
      this.distanceInput = 25000;
      this.activities = [];
      this.employmentTypes = [];
      this.industries = [];
      this.datePeriodStart = null;
      this.datePeriodEnd = null;
      this.dateStartFromNow = null;
      this.dateOpenEnded = null;
      this.salaryRange = [0, 500];
      this.currentSalarySlide = 0;
      this.currentSalaryNumber = null;
      this.salaryType = '';
      this.releasePeriod = {};
      this.gender = '';
      this.userdataFilters = [];
      this.additionalFilters = null;
    },
    emitResetFilterEvent() {
      EventBus.$emit('listing-filter-reset');
      this.$emit('listing-filter-reset');
    },
    getAddressData(addressData) {
      this.whereSearch = addressData;
    },
  },
};
