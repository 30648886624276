export function createObjectFromQuery(query) {
  const result = {};
  for (const key in query) {
    if (!Object.prototype.hasOwnProperty.call(query, key)) {
      continue;
    }
    result[key] = key.includes('[') ? toArray(query[key]) : query[key];
  }
  return result;
}

export function createObjectFromUrlSearchParams(params) {
  const result = {};
  for (const [key, value] of params.entries()) {
    if (key.includes('[')) {
      if (!result[key]) {
        result[key] = [];
      }
      result[key] = result[key].concat(toArray(value));
    } else {
      result[key] = value;
    }
  }
  return result;
}

export function createObjectFromQueryString(query) {
  return createObjectFromUrlSearchParams(new URLSearchParams(query));
}

export function createUrlSearchParamsFromObject(object) {
  const urlSearchParams = new URLSearchParams();

  for (const item in object) {
    if (!Object.prototype.hasOwnProperty.call(object, item) || !object[item]) {
      continue;
    }
    if (Array.isArray(object[item])) {
      for (const arrayItem of object[item]) {
        urlSearchParams.append(item, arrayItem);
      }
    } else {
      urlSearchParams.append(item, object[item]);
    }
  }

  return urlSearchParams;
}

function toArray(value) {
  return Array.isArray(value) ? value : [value];
}
