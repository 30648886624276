
  export default {
    name: 'NoDataToShow',
    props: {
      title: {
        type: String,
        default: 'no_search_results.title',
      },
      subtitle: {
        type: String,
        default: 'no_search_results.subtitle',
      },
      content: {
        type: String,
        default() {
          return this.$route.fullPath.includes('/recruiting')
            ? 'no_search_results.content_organization_context'
            : 'no_search_results.content';
        },
      },
      buttonText: {
        type: String,
        default: 'no_search_results.button_text',
      },
      resetFilter: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
  };
