
  import clearableTabIndex from '@/mixins/clearableTabIndex';
  import ModelItems from '@/mixins/ModelItems';
  import JobsFilter from '@/mixins/JobsFilter';
  export default {
    name: 'JobListingFilter',
    mixins: [clearableTabIndex, ModelItems, JobsFilter],
    props: {
      filterParams: {
        type: String,
        required: false,
        default: undefined,
      },
      useFacets: {
        type: Boolean,
        required: false,
        default: false,
      },
      facets: {
        type: Object,
        required: false,
        default: undefined,
      },
      newSearchArray: {
        type: Array,
        required: false,
        default: undefined,
      },
    },
  };
