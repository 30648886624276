
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    mixins: [ScreenSize],
    data() {
      return {
        sendViaEmailText:
          'Ja, sendet mir bitte die neuesten Jobangebote für diese Suche per E-Mail zu.',
        switch: 'true',
      };
    },
  };
